<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="角色名称">
              <a-input v-model="queryParam.name"/>
            </a-form-item>
          </a-col>
          <a-col :xs="6">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record, index)">删除</a>
        </template>
      </span>
    </s-table>
    <a-modal :width="520" :visible="formVisible"
             :confirmLoading="confirmLoading" :title="modalFlag === 'create' ? '创建角色' : '编辑角色'"
             @ok="handleSubmit" @cancel="formVisible = false">
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-form-model-item label="角色名称" prop="name">
          <a-input v-model="form.name" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.remark"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="资源权限">
          <a-tree
            v-if="resourceTree.length"
            :selectable="false"
            :defaultExpandAll="false"
            :treeData="resourceTree"
            :checkedKeys="actions"
          >
            <!-- <span style="margin-right: 10px" slot="titleCustom" slot-scope="{moduleName}">{{ moduleName }}</span> -->
            <template slot="custom" slot-scope="item">
              <a-checkbox @change="moduleCheckChange($event, item)" :indeterminate="item.extend3"
                          v-model="item.extend1">{{ item.name }}</a-checkbox>
              <template v-if="!item.children">
              </template>
            </template>
          </a-tree>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getSystemPage, deleteSystemInfo, createSystemInfo, updateSystemInfo, getResourceTree } from '@/api/system'
export default {
  components: {
    STable
  },
  data() {
    return {
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '角色名称',
          dataIndex: 'name'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        // if (!parameter.orderby) {
        //   parameter.order = 'desc'
        //   parameter.orderby = 'recDate'
        // }
        return getSystemPage('role', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      modalFlag: 'create',
      formVisible: false,
      currentData: null,
      confirmLoading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      form: {
        id: '',
        name: '',
        remark: ''
      },
      rules: {
        name: [ { required: true, message: '请输入角色名称', trigger: 'blur' } ]
      },
      resourceTree: [],
      actions: []
    }
  },
  created() {
    this.getBasicFormData()
  },
  methods: {
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        id: '',
        name: '',
        remark: ''
      }
      this.getBasicFormData()
      this.formVisible = true
    },
    getBasicFormData() {
      getResourceTree().then(v => {
        this.resourceTree = this.generateTreeSlots(v, null)
      })
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      // this.getBasicFormData()
      this.form = {
        id: record.id,
        name: record.name,
        remark: record.remark
      }
      this.actions = record.menu_code_list
      this.setActions(this.resourceTree, this.actions)
      this.formVisible = true
    },
    handleDelete(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除该条信息？',
        onOk: () => {
          deleteSystemInfo('role', [record.id]).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    generateTreeSlots(data, parent) {
      return data.map(v => {
        v.scopedSlots = { title: 'custom' }
        v.parent = parent
        v.levelNum = (parent && parent.levelNum || 0) + 1
        if (v.children) {
          v.children = this.generateTreeSlots(v.children, v)
        } else {
          v.btnActions = []
        }
        v.extend1 = false // model
        v.extend3 = false // indeterminate
        return v
      })
    },
    btnCheckChange(checkedValues, item) {
      item.extend1 = false
      item.extend3 = false
      const checkedLen = checkedValues.length
      const operLen = item.btnActions.length
      if (checkedLen === operLen) {
        item.extend1 = true
        this.moduleCheckChange(null, item)
      } else if (checkedLen < operLen) {
        if (checkedLen === 0) {
          item.extend3 = false
          this.moduleCheckChange(null, item)
        } else {
          const data = this.getItem(this.resourceTree, item.key, item.levelNum)
          Object.assign(data, item, { extend3: true })
          if (data.parent) {
            this.generateParentChange(data.parent)
          }
        }
      }
    },
    getItem(sourceData, code, levelNum) {
      let d = null
      sourceData.forEach(t => {
        if (t.levelNum === levelNum) {
          if (t.key === code) {
            d = t
          }
        } else if (!d && t.children) {
          d = this.getItem(t.children, code, levelNum)
        }
      })
      return d
    },
    moduleCheckChange(keys, item) {
      const data = this.getItem(this.resourceTree, item.key, item.levelNum)
      // data.extend1 = item.extend1
      // data.extend3 = item.extend3
      Object.assign(data, item)
      if (data.extend1) {
        if (!data.children) {
          const codes = []
          codes.push(item.code)
          data.btnActions = codes
          data.extend3 = false
        } else {
          const codes = []
          codes.push(item.code)
          item.btnActions = codes
          this.generateSubChange(data.children, true)
        }
      } else {
        if (!data.children) {
          data.btnActions = []
          data.extend3 = false
        } else {
          this.generateSubChange(data.children, false)
        }
      }
      if (data.parent) {
        this.generateParentChange(data.parent)
      }
    },
    generateParentChange(parentItem) { // 用来处理父级选框样式
      parentItem.extend1 = false
      parentItem.extend3 = false
      const checkedItems = []
      const interCheckedItems = []
      parentItem.children.forEach(s => {
        if (s.extend1) {
          checkedItems.push(s)
        }
        if (s.extend3) {
          interCheckedItems.push(s)
        }
      })
      if (checkedItems.length === parentItem.children.length) {
        parentItem.extend1 = true
      } else {
        if (checkedItems.length > 0 || interCheckedItems.length > 0) {
          parentItem.extend3 = true
        }
      }
      if (parentItem.parent) {
        this.generateParentChange(parentItem.parent)
      }
    },
    generateSubChange(items, checked) {
      items.forEach(item => {
        item.extend1 = checked
        if (!item.children) {
          const codes = []
          codes.push(item.code)
          item.btnActions = checked ? codes : []
        } else {
          const codes = []
          codes.push(item.code)
          item.btnActions = checked ? codes : []
          this.generateSubChange(item.children, checked)
        }
      })
    },
    setActions(data, buttons) { // 编辑时设置按钮选中情况
      data.forEach(item => {
        if (buttons.includes(item.code)) {
          if (typeof item.children !== 'undefined') {
            this.setActions(item.children, buttons)
            const btnActions = []
            btnActions.push(item.code);
            item.btnActions = btnActions
          } else {
            const btnActions = []
            btnActions.push(item.code);
            item.btnActions = btnActions
            this.btnCheckChange(btnActions, item)
          }
        } else {
          item.btnActions = []
          item.extend3 = false;
          item.extend1 = false;
          if (typeof item.children !== 'undefined') {
            this.setActions(item.children, buttons)
          }
        }
      })
    },
    getActions(source) { // 获取所有按钮选中code Arr
      if (source) {
        source.forEach(item => {
          if (typeof item.children !== 'undefined') {
            if (typeof item.btnActions !== 'undefined' && (item.extend1 || item.extend3)) {
              this.actions = this.actions.concat(item.btnActions)
            }
            this.getActions(item.children)
            if (item.extend1 || item.extend3) {
              if (!this.actions.includes(item.code)) {
                this.actions.unshift(item.code)
              }
            }
          } else {
            if (typeof item.btnActions !== 'undefined') {
              this.actions = this.actions.concat(item.btnActions)
            }
          }
        })
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = createSystemInfo
          if (this.modalFlag === 'update') {
            func = updateSystemInfo
          }
          this.actions = []
          this.getActions(this.resourceTree)
          const data = {
            ...this.form,
            menu_code_list: this.actions
          }
          this.confirmLoading = true
          func('role', data).then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              this.$refs.table.refresh(false)
            }).catch(_ => {
              // this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less">
  .resource-checkbox{
    margin: 0 10px;
  }
</style>
